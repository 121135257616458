import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { firstAidVideo } from "./first-aid-videos.module.scss";

export function FirstAidVideoCard({ video = {} }) {
    console.log(video);
    return (
        <Card className={firstAidVideo}>
            <iframe
                width="100%"
                height="315"
                src={"https://www.youtube.com/embed/" + video.key}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {video.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {video.description.description}
                </Typography>
            </CardContent>
        </Card>
    );
}
