import React from "react";
import { FirstAidVideoCard } from "../card/first-aid-video-card";

export function FirstAidVideoCardList({ videos = [] }) {
    console.log(videos);
    return (
        <div
            style={{
                marginTop: "1rem",
                marginLeft: "auto",
                marginRight: "auto",
                width: "90%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-evenly",
                    rowGap: "2rem",
                    paddingBottom: "2rem",
                }}
            >
                {videos.map((video) => (
                    <FirstAidVideoCard video={video} />
                ))}
            </div>
        </div>
    );
}
