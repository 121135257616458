import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout.jsx";
import PhotoLessHero from "../../components/hero/photoless-hero/photoless-hero.jsx";
import { FirstAidVideoCardList } from "../../components/first-aid-video/card-list/first-aid-video-card-list.jsx";
import { useTranslation } from "react-i18next";
import SEO from "../../components/seo"

export const query = graphql`
    query firstAidVideos($language: String!) {
        allContentfulFirstAidVideo(filter: {node_locale: {eq: $language}}) {
            nodes {
                title
                description {
                    description
                }
                key
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function WoundCareVideos({data: { allContentfulFirstAidVideo: { nodes: firstAidVideos }, },}) {
    const translate = useTranslation().t;
    return (
        <Layout>
            <SEO title="Wound Care Videos"/>
            <PhotoLessHero
                title={translate("wound-care-videos-title")}
                description={translate("wound-care-description")}
                backgroundColor="#6497b1"
            />
            <FirstAidVideoCardList videos={firstAidVideos} />
        </Layout>
    );
}
